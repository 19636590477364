import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

// import ReinssuranceBanner from '../components/eshop/ReinsuranceBanner';
import SlicesContainer from '../components/slices';
import { Partners } from '../components/widgets';

import Seo from '../components/seo';
import { regroup, Product, device, oneMatch } from '../utils';
import { trackProductList } from '../utils/gtm';
import usePreviewData from '../utils/usePreviewData';
import { transformArticle, transformPageV2 } from '../utils/Article';
import Cover from '../screens/homepage/Cover';
import HomepageCoverVideo from '../screens/homepage/VideoCover';
// import { useRef } from 'react';
// import useIntersection from '../utils/useIntersection';
// import Review from '../components/review';

const Wrapper = styled.div`
  display: block;
  width: 100%;

  .bigtitle {
    font-size: 24px;
  }

  @media ${device.laptop} {
    & > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .container {
    .redtitle {
      color: var(--dm-color);
    }
  }
`;

const SiteContainer = styled.div`
  transition: opacity 600ms;
`;

const Separation = styled.hr`
  margin-top: 75px;
  margin-bottom: 75px;
  max-width: 75%;

  border: 0;
  border-bottom: 2px solid var(--dm-color);

  @media ${device.tablet} {
    max-width: 200px;
    margin-top: 70px;
    margin-bottom: 70px;
  }
`;

const IndexPage = ({ data: rawData }) => {
  const data = usePreviewData(rawData);
  const home = regroup(data.prismicHomepage?.data);
  // const coverRef = useRef(null);
  // const intersection = useIntersection(coverRef, {
  //   root: null,
  //   rootMargin: '0px',
  //   threshold: 0.75
  // });

  const displayedProducts = [];
  const products = (data?.products?.nodes || []).map((p) => Product(p));
  const content = (home?.body || [])
    .map((slice) => {
      if (slice.slice_type === 'produits') {
        return {
          ...slice,
          items: (slice.items || [])
            .map((i) => {
              const current = products.find((p) =>
                oneMatch(
                  p.skus,
                  i.product?.variants?.map((v) => v.sku)
                )
              );
              if (!current) {
                return null;
              }
              const result = {
                ...current,
                trackList: 'Homepage',
                trackPosition: displayedProducts.length + 1,
              };

              displayedProducts.push(result);
              return result;
            })
            .filter((p) => p),
        };
      }

      if (slice.slice_type === 'article') {
        const doc = slice.primary.article?.document;
        let finalArticle;

        if (!doc) {
          return null;
        }

        if (doc.type === 'articles') {
          finalArticle = transformArticle(doc);
        } else if (doc.type === 'tuto') {
          finalArticle = transformPageV2(doc);
        }
        return {
          ...slice,
          primary: {
            ...slice.primary,
            article: {
              ...slice.primary.article,
              document: finalArticle,
            },
          },
        };
      }

      return slice;
    })
    .filter((slice) => !!slice);

  trackProductList(
    displayedProducts.map((p) => ({
      sku: p.defaultVariant?.sku,
      name: p.title,
      price: p.defaultVariant?.price,
      variant: p.defaultVariant?.title,
      category: p.productType,
      list: p.trackList,
      position: p.trackPosition,
    }))
  );

  return (
    <Wrapper>
      <Seo
        title="IPONE"
        suffix="Passion First"
        rating={{
          score: data?.trustpilot?.rating,
          count: data?.trustpilot?.total,
        }}
      />
      <Helmet>
        <meta
          name="facebook-domain-verification"
          content="5qm3d1wmbwkpij10g4i89jjakdmp9g"
        />
      </Helmet>
      <Cover {...home?.cover} />
      {/* <HomepageCoverVideo /> */}
      {/* <div ref={coverRef}>
        <HomepageCoverEvent />
      </div> */}
      {/* <Review score={data?.trustpilot?.score?.trustScore} className="trustpilot" /> */}
      {/* {!!home.reinsurance?.display && (
        <ReinssuranceBanner />
      )} */}
      {/* <SiteContainer style={{ opacity: intersection?.isIntersecting ? .35 : 0.8 }}> */}
      <SlicesContainer content={content} />
      {/* </SiteContainer> */}

      <Separation />
      <Partners />
    </Wrapper>
  );
};

export const query = graphql`
  query (
    $prismic: ID!
    $locale: String!
    $fetchProducts: [String!]
  ) # $cmsLocale: String!
  {
    trustpilot: reviewSummary {
      total
      rating
    }
    prismicHomepage(prismicId: { eq: $prismic }) {
      data {
        # configurator_description
        # configurator_title
        # reinsurance_display
        cover_desktop {
          alt
          url
          dimensions {
            height
            width
          }
        }
        cover_mobile {
          alt
          url
          dimensions {
            height
            width
          }
        }
        cover_link {
          target
          url
        }
        # cover_baseline {
        #  alt
        #  url
        #  dimensions {
        #    height
        #    width
        #  }
        # }

        #cover_products {
        #  alt
        #  url
        #  dimensions {
        #    height
        #    width
        #  }
        #}
        body {
          ... on PrismicHomepageDataBodyTuto {
            id
            slice_label
            slice_type
            primary {
              darkmode
              title
            }
          }
          ... on PrismicHomepageDataBodyStories {
            id
            slice_type
            primary {
              title
            }
            items {
              story {
                document {
                  ... on PrismicStories {
                    id
                    url
                    data {
                      title
                      description {
                        html
                      }
                      cover_title {
                        url
                        dimensions {
                          height
                          width
                        }
                        alt
                      }
                      cover_banner
                      cover_background {
                        dimensions {
                          height
                          width
                        }
                        alt
                        url
                      }
                      video_full {
                        embed_url
                        html
                      }
                      video_preview
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomepageDataBodyCarousel {
            id
            slice_type
            primary {
              title
              darkmode
            }
            items {
              title
              link {
                url
                target
              }
              image {
                url
                dimensions {
                  height
                  width
                }
                alt
              }
            }
          }
          ... on PrismicHomepageDataBodyArticle {
            id
            slice_type
            primary {
              title
              article {
                id
                document {
                  ... on PrismicArticles {
                    id
                    tags
                    url
                    type
                    uid
                    date: first_publication_date
                    data {
                      body {
                        ... on PrismicArticlesDataBodyContenu {
                          id
                          slice_type
                          primary {
                            content {
                              text
                            }
                          }
                        }
                      }
                      thumbnail {
                        url
                        alt
                        dimensions {
                          height
                          width
                        }
                      }
                      cover {
                        alt
                        url
                        dimensions {
                          height
                          width
                        }
                      }
                      title
                    }
                  }

                  ... on PrismicTuto {
                    tags
                    url
                    type
                    uid
                    # date: first_publication_date
                    url
                    data {
                      cover {
                        alt
                        url
                        dimensions {
                          height
                          width
                        }
                      }
                      cover_mobile {
                        alt
                        url
                        dimensions {
                          height
                          width
                        }
                      }
                      pageTitle: title {
                        text
                      }
                      subtitle
                      body {
                        ... on PrismicTutoDataBodyContent {
                          id
                          slice_type
                          items {
                            content {
                              text
                            }
                          }
                          primary {
                            section_title
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicHomepageDataBodyPromo {
            id
            slice_type
            primary {
              title
              link {
                url
                target
              }
              padding_top
              padding_bottom
              background_color
              imageMobile: image_mobile {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
              image {
                dimensions {
                  height
                  width
                }
                url
                alt
              }
            }
          }
          ... on PrismicHomepageDataBodyProduits {
            id
            primary {
              title
            }
            items {
              product {
                variants {
                  sku
                }
              }
            }
            slice_type
          }
          ... on PrismicHomepageDataBodyLubadvisor {
            id
            slice_type
          }
        }
      }
    }
    products: allShopifyProduct(
      filter: { skus: { in: $fetchProducts }, locale: { eq: $locale } }
    ) {
      nodes {
        shopifyAdminId
        ...ProductItem
      }
    }
  }
`;

export default IndexPage;
