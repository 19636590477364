import styled from 'styled-components';
import { device } from '../../../utils';

export const Wrapper = styled.section`
  position: relative;
  background-color: #000;
  /* max-height: 600px; */

  h1 {
    position: absolute;
    width: 0;
    height: 0;
    overflow: hidden;
  }
  .container {
    position: relative;
    padding-inline: 0;
  }

  .video {
    display: block;
    background-color: #000;
    line-height: 0;
    aspect-ratio: 1062/1578;

    @media ${device.tablet} {
      aspect-ratio: 2880/900;
    }
  }

  .fx {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    pointer-events: none;

    video {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom left;
    }


    @media (min-width: 1520px) {
      left: -40px;
      right: -40px;

      &:after,
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 40px;
        background: linear-gradient(to right, #000, rgba(0, 0, 0, 0));
        z-index: 101;
      }

      &:after {
        left: auto;
        right: 0;
        background: linear-gradient(to left, #000, rgba(0, 0, 0, 0));
      }
    }
  }
`;

export const MuteButton = styled.div`
  cursor: pointer;
  background: rgba(0, 0, 0, 0.25);
  border: 2px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: transform;
  width: 40px;
  height: 40px;
  box-sizing: border-box;

  svg {
    width: 20px;
    height: 20px;
  }

  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 1100;
  transition: opacity 300ms, transform 300ms;
  transform-origin: bottom right;

  &.mute {
    opacity: 0.4;
    transform: scale(0.6);
  }

  @media ${device.laptop} {
    transform: scale(1.3);
    right: 30px;
  }
`;
